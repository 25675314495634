// Generated by ReScript, PLEASE EDIT WITH CARE

import * as BannerGoogleMaps from "../../../../../styleguide/components/BannerGoogleMaps/BannerGoogleMaps.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as AllJson from "./map-data/all.json";

var allLocationsJson = AllJson;

function MapMulticloud(props) {
  return JsxRuntime.jsx(BannerGoogleMaps.make, {
              title: "Multicloud Regions and Availability",
              description: "With the click of a button, deploy to any region in the world where AWS, Microsoft Azure and GCP are available.",
              allLocationsJson: allLocationsJson,
              color: "Default"
            });
}

var make = MapMulticloud;

var $$default = MapMulticloud;

export {
  allLocationsJson ,
  make ,
  $$default as default,
}
/* allLocationsJson Not a pure module */
